import moment from 'moment';
const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
        resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
        reject(error);
        };
    });
};
const getDaysName = (day = '') => {
    var days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday','Sunday'];
    if (day != ''){
        for (let i = 0; i < days.length; i++) {
            if (day == days[i]){
                return i+1;
            }
        }
    }
    return days;
};
const getGameTypeName = (gametype = '', gameA = []) => {
    var typesArr = ['Single Open Digit', 'Single Close Digit', 'Jodi', 'Single Open Panna', 'Double Open Panna', 'Triple Open Panna', 'Single Close Panna', 'Double Close Panna', 'Triple Close Panna', 'Half Sangam Open Panna Close Digit', 'Half Sangam Close Panna Open Digit', 'Full Sangam'];
    if (gametype !== '' || gametype === 0){
        if (gameA.length > 0){
            var nmes = [];
            for (let i = 0; i < gameA.length; i++) {
                var txt = typesArr[gameA[i]];
                if (gameA[i] == 9 || gameA[i] == 10){
                    txt = txt.replace('Half Sangam ','');
                }
                nmes.push(txt);
            }
            return nmes.join(', ');
        } else {
            return typesArr[gametype];
        }
    }
    return typesArr;
};
const getTrxType = (type = 0) => {
    /* 1=place_bid,2=refund,3=bonus_credited,4=added_by_admin,5=added_by_self,6=winning_withdraw,7=winning_credited,8=deducted_by_admin,9=deposit_bonus_credited,10=bank valid bonus,11=result rollback,12=referral_bonus_credited,13=bank change penalty */
    var typesArr = ['Place Bid', 'Refund', 'Bonus Credited', 'Added By Admin', 'Added By Self', 'Winning Withdraw', 'Winning Credited', 'Deducted By Admin', 'Deposit Bonus Credited', 'Bank Verified Bonus','Result Rollback','Referral Bonus Credited','Bank Change Penalty'];
    if (type != ''){
        return typesArr[type-1];
    }
    return '-';
};
const amountFormat = (amount) => {
    return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 1 }).format(amount)
};
const setTimeFormat = (time) =>{
    time = time.split(':');
    var date = new Date();
    date.setHours(time[0]);
    date.setMinutes(time[1]);
    return moment(date).format('hh:mm A');
}
const getDateFormat = (date = '') => {
    let ddd = date;
    if (date == '') {
        // ddd = new Date();
        ddd = new Date();
        // ddd = new Date('2024-01-09 00:12:10');
    }
    var m = (ddd.getMonth() + 1);;
    var dy = ddd.getDate();
    m = (m <= 9) ? '0' + m : m;
    dy = (dy <= 9) ? '0' + dy : dy;
    var cdate = ddd.getFullYear() + '-' + m + '-' + dy;
    return cdate;
};
const getIsAllowEditBid = (time1, time2, isclose = false) => {
    let openStr = time1.split(':');
    let closeStr = time2.split(':');
    var today = new Date();
    var current = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    console.log(99, current);

    let currentStr = current.split(':');
    let openSeconds = parseInt(openStr[0]) * 3600 + parseInt(openStr[1]) * 60 + ((openStr.length == 3) ? parseInt(openStr[2]) : 0);
    let closeSeconds = parseInt(closeStr[0]) * 3600 + parseInt(closeStr[1]) * 60 + ((closeStr.length == 3) ? parseInt(closeStr[2]) : 0);
    let currentSeconds = parseInt(currentStr[0]) * 3600 + parseInt(currentStr[1]) * 60 + ((currentStr.length == 3) ? parseInt(currentStr[2]) : 0);
    let threeAmSeconds = parseInt(3) * 3600 + 0 + 0;
    if (openSeconds > closeSeconds) {
        if (isclose) {
            if (currentSeconds < openSeconds && currentSeconds > closeSeconds && currentSeconds < threeAmSeconds) {
                return false;
            } else if (currentSeconds > closeSeconds && currentSeconds < threeAmSeconds) {
                return false;
            }
        } else {
            if (currentSeconds > openSeconds) {
                return false;
            } else if (currentSeconds < openSeconds && currentSeconds < closeSeconds) {
                return false;
            } else if (currentSeconds < openSeconds && currentSeconds < threeAmSeconds) {
                return false;
            }
        }
    } else {
        if (isclose) {
            if (currentSeconds > closeSeconds) {
                return false;
            } else if (currentSeconds < threeAmSeconds) {
                return false;
            }
        } else {
            if (currentSeconds > openSeconds) {
                return false;
            } else if (currentSeconds < threeAmSeconds) {
                return false;
            }
        }
    }
    return true;
};
const getIsAllowEditBidBkp = (time1, time2, isclose = false) => {
    let str1 = time1.split(':');
    let close = time2.split(':');
    var today = new Date();
    var currtime = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let str2 = currtime.split(':');
    /* timeSeconds1 => (Bid Open Time), timeSeconds2=Current Time */
    let timeSeconds1 = parseInt(str1[0]) * 3600 + parseInt(str1[1]) * 60 + ((str1.length == 3) ? parseInt(str1[2]) : 0);
    let timeSeconds2 = parseInt(str2[0]) * 3600 + parseInt(str2[1]) * 60 + ((str2.length == 3) ? parseInt(str2[2]) : 0);
    let closeSeconds2 = parseInt(close[0]) * 3600 + parseInt(close[1]) * 60 + ((close.length == 3) ? parseInt(close[2]) : 0);
    if (isclose) {
        //console.log(256, 'close :: ',close, 'current :: ',str2, (timeSeconds2 > closeSeconds2));
        if (timeSeconds1 > closeSeconds2) {
            return true;
        } else if (timeSeconds2 > closeSeconds2) {
            return false;
        }
    } else {
        if (timeSeconds1 > closeSeconds2) {
            if ((timeSeconds2 > timeSeconds1) || (timeSeconds2 < closeSeconds2)) {
                return false;
            }
        } else if (timeSeconds2 > timeSeconds1) {
            return false;
        }
    }
    return true;
};
const getMobileNoField = (user) => {
    if (typeof user) {
        var code = user.country_code;
        if (typeof user.country_code == 'string' && user.country_code.slice(0, 1) != '+') {
            code = '+' + code;
        }
        return code + ' ' + user.mobile_no
    }
    return '-';
};
const isAllowRefund = (open_time,close_time) => {
    let openStr = open_time.split(':');
    let closeStr = close_time.split(':');
    var today = new Date();
    var currtime = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    //currtime = "01:25:00";
    let currentStr = currtime.split(':');
    
    let openSeconds = parseInt(openStr[0]) * 3600 + parseInt(openStr[1]) * 60 + ((openStr.length == 3) ? parseInt(openStr[2]) : 0);
    let closeSeconds = parseInt(closeStr[0]) * 3600 + parseInt(closeStr[1]) * 60 + ((closeStr.length == 3) ? parseInt(closeStr[2]) : 0);
    let currentSeconds = parseInt(currentStr[0]) * 3600 + parseInt(currentStr[1]) * 60 + ((currentStr.length == 3) ? parseInt(currentStr[2]) : 0);
    let threeAmSeconds = parseInt(3) * 3600 + parseInt(0) * 60 + 0;
    if (openSeconds > closeSeconds) {
        if ((currentSeconds > closeSeconds) && (currentSeconds < threeAmSeconds)){
            return true;
        }
    } else if (currentSeconds > closeSeconds) {
        return true;
    } else if ((currentSeconds < closeSeconds) && (currentSeconds < threeAmSeconds)){
        return true;
    }
    return false;
};
const isAllowRefundPartial = (open_time, close_time, isClose = false) => {
    let openStr = open_time.split(':');
    let closeStr = close_time.split(':');
    var today = new Date();
    var currtime = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    //currtime = "01:25:00";
    let currentStr = currtime.split(':');

    let openSeconds = parseInt(openStr[0]) * 3600 + parseInt(openStr[1]) * 60 + ((openStr.length == 3) ? parseInt(openStr[2]) : 0);
    let closeSeconds = parseInt(closeStr[0]) * 3600 + parseInt(closeStr[1]) * 60 + ((closeStr.length == 3) ? parseInt(closeStr[2]) : 0);
    let currentSeconds = parseInt(currentStr[0]) * 3600 + parseInt(currentStr[1]) * 60 + ((currentStr.length == 3) ? parseInt(currentStr[2]) : 0);
    let threeAmSeconds = parseInt(3) * 3600 + parseInt(0) * 60 + 0;
    if (isClose){
        if (openSeconds > closeSeconds) {
            if ((currentSeconds > closeSeconds) && (currentSeconds < threeAmSeconds)) {
                return true;
            }
        } else if (currentSeconds > closeSeconds) {
            return true;
        } else if ((currentSeconds < closeSeconds) && (currentSeconds < threeAmSeconds)) {
            return true;
        }
    } else {
        if (openSeconds > closeSeconds) {
            if (currentSeconds < closeSeconds && (currentSeconds < threeAmSeconds)){
                return true;
            }
        } else if (currentSeconds > openSeconds){
            return true;
        }
    }
    return false;
};
/* 21-01-2022 */
const getMinusOneDayDate = (passeddate) => {
    var today = new Date();
    if (passeddate != ''){
        today = new Date(passeddate);
    }
    let todayDt = new Date();
    var time2 = todayDt.getHours() + ":" + todayDt.getMinutes() + ":" + todayDt.getSeconds();
    //time2 = "02:00:00";
    let currTime = time2.split(':');
    let currentTime = parseInt(currTime[0]) * 3600 + parseInt(currTime[1]) * 60 + ((currTime.length == 3) ? parseInt(currTime[2]) : 0);
    let threeamTime = parseInt(3) * 3600 + 0 + 0;
    if (currentTime <= threeamTime) {
        today.setDate(today.getDate() - 1);
        return getDateFormat(today);
    }
    return passeddate;
};
export { convertToBase64, getDaysName, getGameTypeName, getTrxType, amountFormat, setTimeFormat, getDateFormat, getIsAllowEditBid, getMobileNoField, isAllowRefund, isAllowRefundPartial, getMinusOneDayDate};