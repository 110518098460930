import React, { useEffect, useState } from 'react';
import { Link, withRouter, useHistory } from "react-router-dom";
import logos from '../images/logo-small.png';
import site_logo from '../images/logo.png';
import Api from '../Services/Api';
import Alert from '../Services/Alert';
import logoDark from '../images/logo.png';
import avtar from '../images/avatar.png';

import { useForm } from 'react-hook-form';
import { Form, FormLabel, Button, Modal } from 'react-bootstrap';

export const Userlist = (props) => {
    var history = useHistory();
    var [sessadminname, setSessadminname] = useState('');

    var dfltAddMoneyData = {
        mobile_no: "", amount: "", show_not_show: "1"
    };
    const [addMoney, setAddMoney] = useState(dfltAddMoneyData);
    const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onChange", defaultValues: addMoney });
    const [showAddMoney, setShowAddMoney] = useState(false);
    const handleCloseAddMoney = () => setShowAddMoney(false);
    const handleShowAddMoney = () => {
        setShowAddMoney(true);
        reset(dfltAddMoneyData);
    };
    
    const onSubmitAddMoney = async (data) => {
        var sdata = data;
        console.log(sdata);
        Api.post('user/add-money-to-wallet', sdata).then((res) => res.data).then(res => {
            if (res.status) {
                Alert.success(res.message);
                handleCloseAddMoney();
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }

    const doLogout = () => {
        Api.post('logout').then((res) => res.data).then(res => {
            if (res.status) {
                localStorage.clear();
                history.push("/login");
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }

    useEffect(() => {
        if (localStorage.getItem('admin')) {
            var sessData = localStorage.getItem('admin');
            sessData = JSON.parse(sessData);
            var sFullname = sessData.firstname + ' ' + sessData.lastname;
            setSessadminname(sFullname);
        }
    }, []);
    return (
        <>
            <div id="layout-wrapper">
                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box">
                                <a href="/dashboard" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logos} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoDark} alt="" height="17" />
                                    </span>
                                </a>

                                <Link to="/dashboard" className="logo logo-light">
                                {/* <h1 className="mt-4">LOGO</h1> */}
                                <span className="logo-sm">
                                    <img src={logos} alt="" width="50px" />
                                </span>
                          <span className="logo-lg">
                              <img src={site_logo} alt="" width="150px" height="60px" />
                          </span>
                                </Link>
                                {/* <a href="#;" className="logo logo-light">
                        <h1 className="mt-4">LOGO</h1>
                          <span className="logo-sm">
                              <img src="./assets/images/logo-light.svg" alt="" height="22" />
                          </span>
                          <span className="logo-lg">
                              <img src="./assets/images/logo-light.png" alt="" height="19" />
                          </span>
                      </a> */}
                            </div>

                            <button type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                                <i className="fa fa-fw fa-bars"></i>
                            </button>
                        </div>

                        <div className="d-flex">
                            <div className="dropdown d-inline-block d-lg-none ms-2">
                                <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="mdi mdi-magnify"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                    aria-labelledby="page-header-search-dropdown">

                                    <form className="p-3">
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="dropdown d-inline-block">
                                <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img className="rounded-circle header-profile-user" src={avtar}
                                        alt="Header Avatar" />
                                    <span className="d-none d-xl-inline-block ms-1" key="t-henry">{sessadminname}</span>
                                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end">

                                    <Link to="/change-password" className="dropdown-item">
                                        <i className="bx bx-lock font-size-16 align-middle me-1"></i> <span key="t-profile">Change Password</span>
                                    </Link>
                                    <div className="dropdown-divider"></div>
                                    <button className="dropdown-item text-danger" type="button" onClick={() => doLogout()}><i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span key="t-logout">Logout</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {/* Left Sidebar Start */}
                <div className="vertical-menu">
                    <div data-simplebar className="h-100">
                        {/* Sidemenu  */}
                        <div id="sidebar-menu">
                            {/* Left Menu Start */}
                            <ul className="metismenu list-unstyled" id="side-menu">
                                <li className="menu-title" key="t-menu">Menu</li>
                                <li>
                                    <Link to="/dashboard" className="waves-effect">
                                        <i className="bx bx-home-circle"></i>
                                        <span key="t-dashboards">Dashboard</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/users" className="waves-effect">
                                        <i className='bx bx-slider'></i>
                                        <span key="t-calendar">Users List</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/daily-report" className="waves-effect">
                                        <i className='bx bx-slider' ></i>
                                        <span key="t-calendar">Daily Reports</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/bid-report" className="waves-effect">
                                        <i className='bx bx-slider'></i>
                                        <span key="t-calendar">Win / Place Bid Reports</span>
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link to="/color-game-report" className="waves-effect">
                                        <i className='bx bx-slider'></i>
                                        <span key="t-calendar">Color Game Reports</span>
                                    </Link>
                                </li> */}
                                <li>
                                    <Link to="/pending-withdraw/withdraws" className="waves-effect">
                                        <i className='bx bx-slider' ></i>
                                        <span key="t-calendar">Bulk Withdrawals</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/withdraws" className="waves-effect">
                                        <i className='bx bx-slider' ></i>
                                        <span key="t-calendar">Withdrawals List</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/transactions" className="waves-effect">
                                        <i className='bx bx-slider' ></i>
                                        <span key="t-calendar">Market Transactions</span>
                                    </Link>
                                </li>
                                <li>
                                    <a href="#" className="waves-effect" onClick={() => handleShowAddMoney()}>
                                        <i className="bx bx-slider"></i>
                                        <span key="t-file-manager">Add Money</span>
                                    </a>
                                </li>
                                <li>
                                    <Link to="/wallet-money" className="waves-effect">
                                        <i className='bx bx-slider' ></i>
                                        <span key="t-calendar">Payment Status</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/markets" className="waves-effect">
                                        <i className='bx bx-slider'></i>
                                        <span key="t-calendar">Markets List</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/sliders" className="waves-effect">
                                        <i className='bx bx-slider' ></i>
                                        <span key="t-calendar">Sliders</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/notice-board" className="waves-effect">
                                        <i className='bx bx-slider' ></i>
                                        <span key="t-calendar">Notice Board</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/notifications" className="waves-effect">
                                        <i className='bx bx-slider' ></i>
                                        <span key="t-calendar">Notifications</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/upload-apk" className="waves-effect">
                                        <i className='bx bx-slider' ></i>
                                        <span key="t-calendar">Upload APK</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/settings" className="waves-effect">
                                        <i className='bx bx-slider' ></i>
                                        <span key="t-calendar">Settings</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        {/* Sidebar  */}
                    </div>
                </div>
                {/* Left Sidebar End */}
            </div>

            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            2024 © Indi1
                        </div>
                        <div className="col-sm-6">
                            <div className="text-sm-end d-none d-sm-block">
                                {/* Design & Develop by Themesbrand */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            
            <Modal show={showAddMoney} onHide={handleCloseAddMoney}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Money To Users Wallet</Modal.Title>
                </Modal.Header>
                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmitAddMoney)}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-12">
                                <Form.Group className="mb-3" controlId="formTitle">
                                    <Form.Label>Mobile No</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Mobile No" className="form-control" {...register("mobile_no", {
                                        required: true, maxLength: 10, pattern: {
                                            value: /^[0-9]*$/
                                        },
                                    })} />
                                    <div className="error">
                                        {errors.mobile_no?.type === 'required' && "Mobile No is required"}
                                        {errors.mobile_no?.type === 'pattern' && "Only digits allowed"}
                                        {errors.mobile_no?.type === 'maxLength' && "Maximum 10 digits allowed"}
                                    </div>
                                </Form.Group>
                            </div>

                            <div className="col-lg-12">
                                <Form.Group className="mb-3" controlId="formTitle">
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Amount" className="form-control" {...register("amount", {
                                        required: true, maxLength: 10, pattern: {
                                            value: /^[0-9]*$/
                                        },
                                    })} />
                                    <div className="error">
                                        {errors.amount?.type === 'required' && "Amount is required"}
                                        {errors.amount?.type === 'pattern' && "Only digits allowed"}
                                        {errors.amount?.type === 'maxLength' && "Maximum 10 digits allowed"}
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-lg-12">
                                <Form.Group className="mb-3" controlId="formTitle">
                                    <Form.Label>Show Transaction To User</Form.Label>
                                    <div className="col-lg-12">
                                        <Form.Check inline label="Show To User" ref={register} value="1" id="active-status" type="radio" {...register("show_not_show")} />
                                        <Form.Check inline label="Not Show To User" ref={register} id="inactive-status" type="radio" value="0" {...register("show_not_show")} />
                                        <div className="error">
                                            {errors.show_not_show?.type === 'required' && "Show To User is required"}
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseAddMoney}>Close</Button>
                        <Button variant="primary" type="submit">Save</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default withRouter(Userlist);